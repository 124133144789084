import React from 'react';
import ReactDOM from "react-dom"
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import TableTic from './../../components/page/table';
import Home from './../../screens/Home';
import './GameElement.scss';
import { gameElementService } from '../../services';
import { Button, Card, Accordion, Row, Col, Form, Modal } from 'react-bootstrap';
import ReactTags from 'react-tag-autocomplete';
import queryString from 'query-string';


class GameElement extends Home{


  constructor(props) {
    super(props);
    
    // Obtener parámetros de búsqueda de la URL
    const params = queryString.parse(this.props.location.search);
    const filtertype = params.isBadge ? "badge" : params.isHint ? "hint" : "";
  
    // Inicializar estado
    this.state = {
      headers: ["id", "Título", "Tipo", "Sinopsis", "Banner", "Imagen", "Ruta", "Popup",  "ranking"],
      fields: ["id", "title", "tipo", "shortSynopsis", "banner", "image", "routeId.name", "popup.title", "ranking" ],
      data: [],
      type: ["text", "text","text", "text", "image", "image", "text", "text", "text"],
      row: 0,
      total: 0,
      sizepage: 20,
    
      filterid: params.id || "",
      filtertitle: params.title || "",
      filtershortSynopsis: params.shortSynopsis || "",
      filterbanner: params.banner || "",
      filterrouteId: params.routeId,
      filterpopup: params.popup,
      filternumRecords: params.numRecords || "",
      filternumSubscriptions: params.numSubscriptions || "",
      filterranking: params.ranking || "",
      filterbadge: params.isBadge === 'true',
      filterhint: params.isHint === 'true',
      filtertype: filtertype,
      query: this.getFilter(), 
      routes: [], 
      link: "/gameelements", 
      showconfirm: false, 
      showconfirmExport: false, 
      page: params.page ? parseInt(params.page) : 0, 
    };
  
    this.callGameElement(
      this.state.page, 
      this.state.filterid, 
      this.state.filtertitle,
      this.state.filtershortSynopsis,
      this.state.filterbanner,
      this.state.filterrouteId,
      this.state.filterpopup,
      this.state.filternumRecords,
      this.state.filternumSubscriptions,
      this.state.filterranking,
      this.state.filterhint,
      this.state.filterbadge
    );
  
    this.inputup = React.createRef();
  }
  
  


  callGameElement(offset, id, title, shortSynopsis, banner, routeId, popupId, numRecords, numSubscriptions, ranking, isBadge, isHint) {
    let callback = (datarows, error) => {
        if (datarows) {
          console.log(datarows); 
          const processedData = datarows.map(row => ({
              ...row,
              tipo: row.isBadge ? "Insignia" : (row.isHint ? "Pista" : "N/A") 
          }));
            this.setState({ data: processedData, total: datarows.total });
        }
    }
  
    gameElementService.search(
        offset,
        20,
        shortSynopsis,
        null,
        isHint ? true : null, 
        isBadge ? true : null,
        title,
        routeId,
        popupId,
        callback
    );
  }


  handleChange(event) {
    const { name, value } = event.target; 
  
    switch (name) {
        case "id":
            this.setState({ filterid: value });
            break;
        case "title":
            this.setState({ filtertitle: value });
            break;
        case "shortSynopsis":
            this.setState({ filtershortSynopsis: value });
            break;
        default:
            break;
    }
    if (name === "type") {
      if (value === "badge") {
          this.setState({ 
              filtertype: value, 
              filterbadge: true, 
              filterhint: null 
          });
      } else if (value === "hint") {
          this.setState({ 
              filtertype: value, 
              filterbadge: null,
              filterhint: true 
          });
      } else {
          this.setState({ 
              filtertype: value, 
              filterbadge: null, 
              filterhint: null 
          });
      }
  } else {
      this.setState({ [name]: value });
  }
  }



     clickCell(id){
       window.location.href="/gameelement?id="+id;
     }


     clickDel(id){
       let callback = (datarows, error) => {
          window.location.reload();
       }
       gameElementService.remove(id, callback);
    }

    getFilter() {
      const filters = [];
      if (this.state.filtertitle) {
          filters.push(`title=${this.state.filtertitle}`);
      }
      if (this.state.filtershortSynopsis) {
          filters.push(`shortSynopsis=${this.state.filtershortSynopsis}`);
      }
      if (this.state.filterbanner) {
          filters.push(`banner=${this.state.filterbanner}`);
      }
      if (this.state.filterrouteId) {
          filters.push(`routeId=${this.state.filterrouteId}`);
      }
      if (this.state.filterpopup) {
          filters.push(`popup=${this.state.filterpopup}`);
      }
      if (this.state.filternumRecords) {
          filters.push(`numRecords=${this.state.filternumRecords}`);
      }
      if (this.state.filternumSubscriptions) {
          filters.push(`numSubscriptions=${this.state.filternumSubscriptions}`);
      }
      if (this.state.filterranking) {
          filters.push(`ranking=${this.state.filterranking}`);
      }
      if (this.state.filterbadge === true) {
        filters.push(`isBadge=true`);
      }
      if (this.state.filterhint === true) {
          filters.push(`isHint=true`);
      }
  
      return filters.length ? filters.join('&') : '';
  }


  handleClickClear(event) {
    window.location.href="/gameelements";
  }
   handleClickSearch(event) {
     this.setState({query:this.getFilter()});
     window.location.href="/gameelements?page=0&"+this.getFilter();
   }


  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title="Listado de elementos de juego" newbutton={true} link="/gameelementnew"  />
         <Accordion defaultActiveKey="0">
           <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="default" eventKey="0">
                Filtrar
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="filterpanel">
               <Row>
                <Col sm="3">
                    <Form.Group >
                      <Form.Label>Título</Form.Label>
                      <Form.Control type="text" placeholder="" name="title" value={this.state.filtertitle} onChange={event => this.handleChange(event)} />
                    </Form.Group>
                </Col>
                <Col sm="3">
                    <Form.Group >
                      <Form.Label>Sinopsis</Form.Label>
                      <Form.Control type="text" placeholder="" name="shortSynopsis" value={this.state.filtershortSynopsis} onChange={event => this.handleChange(event)} />
                    </Form.Group>
                </Col>
                <Col sm="3">
                  <Form.Group controlId="formType">
                        <Form.Label>Tipo de elemento</Form.Label>
                        <Form.Control as="select" name="type" value={this.state.filtertype} onChange={event => this.handleChange(event)}>
                          <option value="">---</option>
                          <option value="badge">Insignia</option>
                          <option value="hint">Pista</option>
                        </Form.Control>
                      </Form.Group>
                  </Col>
              </Row>
                <Row>
                    <Col className="text-right">
                        <Button variant="default" className="" onClick={event => this.handleClickClear(event)}>Limpiar</Button>
                        <Button variant="primary" className="" onClick={event => this.handleClickSearch(event)}>Filtrar</Button>
                    </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
         </Accordion>
         <TableTic  headers={this.state.headers} fields={this.state.fields} data={this.state.data} row={this.state.row} total={this.state.total} page={this.state.page} sizepage={this.state.sizepage} type={this.state.type} handler={this.clickCell} link={this.state.link} query={this.state.query} handlerdel={this.clickDel}  />

      </div>
      </>
  };
}

export default GameElement;
