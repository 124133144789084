import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import Home from './../../screens/Home';
import { Button, Row, Col, Form, Image } from 'react-bootstrap';
import { stationService } from '../../services';
import { programService } from '../../services';
import './Emisoras.scss';
import toast from 'toasted-notes'
import queryString from 'query-string';
import ReactTags from 'react-tag-autocomplete';


class EmisoraEdit  extends Home{


  constructor(props) {
      super(props)
      let params = queryString.parse(this.props.location.search);
      this.state.station = {name:"",description:"",url:"",image:""};
      this.state.buttonvisibles = true;
      
      if (params.id) {
        this.state.idstation = params.id;
        this.callSearch();
      } else{
        this.state.idstation = -1;
      }
      if (this.state.station.image) {
        this.state.imagePreviewUrl = this.state.station.image;
      }

      this.handlerUpload = this.handlerUpload.bind(this);
      this.handlerDelete = this.handlerDelete.bind(this);

      this.reactTags = React.createRef()
      this.callTags();
  }

  callSearch (){

      let callback = (data, error) => {
        if (data) {
           this.setState({station:data, imagePreviewUrl: data.image});
        } else {
          const msg = "No se ha encontrado la emisora"
          toast.notify(msg, {position:'top', duration: 5000})
          this.setState({buttonvisibles:false});
        }

      }
      stationService.one(this.state.idstation, callback);
  }

  callTags (){

    let callback = (datarows, error) => {
      if (datarows) {
         this.setState({tags:datarows});
      }
    }
    programService.tags(callback);
  }

  onDelete (i) {
    const tags = this.state.station.tags.slice(0)
    tags.splice(i, 1)
    var station = this.state.station;
    station.tags = tags;
    this.setState({station:station});
  }
  
  onAddition (tag) {
    const tags = [].concat(this.state.station.tags, tag)
    var station = this.state.station;
    station.tags = tags;
    this.setState({station:station});
  }


  handleChange(event){
       const value = event.target.value
       var station = this.state.station;
       if (event.target.name === "name") {
         station.name = value;
       } else if (event.target.name === "url") {
         station.url = value;
       } else if (event.target.name === "description") {
         station.description = value;
       } else if (event.target.name === "position") {
         station.position = value;
       } else if (event.target.name === "image"){
         station.image = value;
         this.setState({ imagePreviewUrl: value });
       }



       this.setState({station:station});
  }


  validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+\\/=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

   handleClickSave(event) {
     if (this.state.station.name && this.state.station.url && this.state.station.name !== "" && this.state.station.url !== "" && this.validURL(this.state.station.url )) {
       if(this.state.idstation !== -1){
         //EDIT
         this.callEdit();
       } else {
         //CREATE
         this.callCreate();
       }
     } else if (!this.state.station.name || this.state.station.name === "" ) {
         const msg = "El nombre es obligatorio"
         toast.notify(msg, {position:'top', duration: 5000})
     } else {
           const msg = "La url no tiene un formato correcto";
           toast.notify(msg, {position:'top', duration: 5000});
     }


   }

   callCreate (){

       let callback = (datarows, error) => {
         if (datarows && datarows.data) {
            this.setState({station:datarows.data});
            this.setState({idstation:datarows.data.id});
            window.location.href="/emisora?id="+datarows.data.id;
         }

       }
       stationService.create(this.state.station, callback);
   }

   callEdit (){

       let callback = (datarows, error) => {
         if (datarows && datarows.data) {
             const msg = "Se ha guardado correctamente";
             toast.notify(msg, {position:'top', duration: 5000});
         }

       }
       stationService.edit(this.state.idstation,this.state.station, callback);
   }

   handleClickClear(event) {
     window.location.href="/emisoras/";
   }

   handlerUpload(image){
     console.log('Upload 2 ' + image);
     var station = this.state.station;
     station.image = image;
     this.setState({station:station});
   }

   handlerDelete(){
     var station = this.state.station;
     station.image = "";
     this.setState({station:station});
   }



  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title={(() => { if (this.state.idstation !== -1) return "Editar emisora"; else return "Nueva emisora";})() } newbutton={false} link=""/>
         <Row>
            <Col sm="12">
                <Form.Group >
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control type="text" placeholder="" name="name" value={this.state.station.name} onChange={event => this.handleChange(event)} required/>
                </Form.Group>
            </Col>
            <Col sm="12">
                 <Form.Group >
                   <Form.Label>Url</Form.Label>
                   <Form.Control type="text" placeholder="" name="url" value={this.state.station.url} onChange={event => this.handleChange(event)} required/>
                 </Form.Group>
             </Col>
             <Col sm="6">
                  <Form.Group >
                    <Form.Label>Posicion</Form.Label>
                    <Form.Control type="number" placeholder="" name="position" value={this.state.station.position} onChange={event => this.handleChange(event)} required/>
                  </Form.Group>
              </Col>
              <Col sm="6">
                  <Form.Group >
                    <Form.Label>Tags</Form.Label>
                    <ReactTags allowNew={true} className="tagsinput" ref={this.reactTags} tags={this.state.station.tags} suggestions={this.state.tags} onDelete={this.onDelete.bind(this)} onAddition={this.onAddition.bind(this)} placeholderText="Nuevas etiquetas"/>
                  </Form.Group>
            </Col>
             <Col sm="12">
                  <Form.Group >
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control as="textarea" rows="3" placeholder="" name="description" value={this.state.station.description} onChange={event => this.handleChange(event)} />
                  </Form.Group>
              </Col>
              
              <Col sm="12">
                <Form.Label>Imagen</Form.Label>
                <Form.Control name="image" 
                  value={this.state.station.image} 
                  onChange={event => this.handleChange(event)} />
                <div className='paneImage'>
                  <Image src={this.state.imagePreviewUrl} alt="Preview" fluid/>
                </div>
              </Col>
         </Row>
         <Row>
             <Col className="text-right">
                 <Button variant="default" className="" onClick={event => this.handleClickClear(event)}>Cancelar</Button>
                 {this.state.buttonvisibles?<Button variant="primary" className="" onClick={event => this.handleClickSave(event)}>Guardar</Button>:""}
             </Col>
         </Row>
    </div></>
  };
}

export default EmisoraEdit;
