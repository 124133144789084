import BaseService from './_base.service';

export const gameElementService = {
    all,
    search,
    one,
    create,
    remove,
    edit
};

function all(callback) {
    return BaseService.get("/game-element/all/", callback);
}

function one(id,callback) {
    return BaseService.get("/game-element/one/"+id, callback);
}

function search(page, size, shortSynopsis, longSynopsis, isBadge, isHint, title, routeId, popupId, callback) {

    let params = new URLSearchParams();

    if (page !== undefined && page !== null) params.append("page", page);
    if (size !== undefined && size !== null) params.append("size", size);
    if (shortSynopsis) params.append("shortSynopsis", shortSynopsis);
    if (longSynopsis) params.append("longSynopsis", longSynopsis);
    if (isBadge !== undefined && isBadge !== null) params.append("isBadge", isBadge);
    if (isHint !== undefined && isHint !== null) params.append("isHint", isHint);
    if (title) params.append("title", title);
    if (routeId !== undefined && routeId !== null) params.append("routeId", routeId);
    if (popupId !== undefined && popupId !== null) params.append("popupId", popupId);
    console.log("isBadge:", isBadge, "isHint:", isHint);
    const url = "/game-element/search?" + params.toString();
    return BaseService.get(url, callback, true);
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post("/game-element/", body, callback, true)
}

function remove(id,callback) {
    return BaseService.delete("/game-element/"+id, callback);
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put("/game-element/"+id, body, callback, true)
}