import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import TableTic from './../../components/page/table';
import Home from './../../screens/Home';
import './Ruta.scss';
import { routeService } from '../../services';
import { Button, Card, Accordion, Row, Col, Form, Modal } from 'react-bootstrap';
import queryString from 'query-string';


class Ruta extends Home{


  constructor(props) {
      super(props)
      let params = queryString.parse(this.props.location.search);
      this.state['headers'] =  ["id","Nombre", "Ranking", "Tipo de ruta"];
      this.state['fields'] = ["id", "name", "ranking", "routeType"];
      this.state['type'] = ["text", "text", "text", "text"];
      this.state['data'] = [];
      this.state['row'] = 0;
      this.state['total'] = 0;
      this.state['sizepage'] = 20;
      this.state['filtertitle'] = params.title?params.title:"";
      this.state['query'] = this.getFilter();
      this.state['routes'] = [];
      this.state['routes'] = [];
      this.state['link'] = "/rutas";
      //this.state['showconfirm'] = false;
      //this.state['showconfirmExport'] = false;

      if (params.page) {
        this.state['page'] = params.page;
        this.callRoute(params.page,this.state.filtertitle);
      } else {
        this.state['page'] = 0;
        this.callRoute(0,this.state.filtertitle);
        console.log(this.callRoute(0,this.state.filtertitle));
      }

      this.inputup = React.createRef();
  }


  callRoute(offset, name) {
    let callback = (datarows, error) => {
      if (datarows) {
        const updatedData = datarows.data.map(route => ({
          ...route,
          routeType: this.getRouteType(route),
        }));
  
        this.setState({ data: updatedData, total: datarows.total });
      }
    };
    routeService.search(offset, name, callback);
  }
  
  
  getRouteType(route) {
    if (route.isRoad) {
      return "En coche";
    } else if (route.isGamified) {
      return "Gamificada";
    } else {
      return "Patrimonial";
    }
  }
  
   handleChange(event){
       const value = event.target.value

       if (event.target.name === "title") {
           this.setState({filtertitle:value});
       }
     }

     clickCell(id){
       window.location.href="/ruta?id="+id;
     }


     clickDel(id){
       let callback = (datarows, error) => {
          window.location.reload();
       }
       routeService.remove(id, callback);
    }

    getFilter(){

       var query=""
       if(this.state.filtertitle !== ""){
         if(query !== ""){
            query = query + "&";
         }
         query = query + "title=" + this.state.filtertitle ;
       }
       return query;
     }


  handleClickClear(event) {
    window.location.href="/rutas";
  }
   handleClickSearch(event) {
     this.setState({query:this.getFilter()});
     window.location.href="/rutas?page=0&"+this.getFilter();
   }

//    handleUpload(){
//     this.inputup.current.click()
//  }

//    handleSelectedExcel(event){
//     const value = event.target.files[0];

//     if (value) {
//       let callback = (data, error) => {
//         if (data) {
//           this.setState({showconfirm:true});
//         }
//       }
//       routeService.importfile(value, callback);
//     }

//   }

  //  handleExport(event){
  //   let callback = (datarows, error) => {
  //       this.setState({showconfirmExport:true});
  //   }
  //   routeService.exportProgram(callback);

  // }


  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title="Listado de rutas" newbutton={true} link="/rutanew"  />
         <Accordion defaultActiveKey="0">
           <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="default" eventKey="0">
                Filtrar
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="filterpanel">
               <Row>
                  <Col sm="3">
                    <Form.Group >
                      <Form.Label>Nombre</Form.Label>
                      <Form.Control type="text" placeholder="" name="title" value={this.state.filtertitle} onChange={event => this.handleChange(event)} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                    <Col className="text-right">
                        <Button variant="default" className="" onClick={event => this.handleClickClear(event)}>Limpiar</Button>
                        <Button variant="primary" className="" onClick={event => this.handleClickSearch(event)}>Filtrar</Button>
                    </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
         </Accordion>
         <TableTic  headers={this.state.headers} fields={this.state.fields} data={this.state.data} row={this.state.row} total={this.state.total} page={this.state.page} sizepage={this.state.sizepage} type={this.state.type} handler={this.clickCell} link={this.state.link} query={this.state.query} handlerdel={this.clickDel}  />

      </div>
      </>
  };
}

export default Ruta;
