import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import Home from './../../screens/Home';
import { Button, Row, Col, Form, Image } from 'react-bootstrap';
import './Ruta.scss';
import toast from 'toasted-notes'
import { routeService } from '../../services';
import queryString from 'query-string';
import ReactTags from 'react-tag-autocomplete';


class RutaEdit  extends Home{


  constructor(props) {
      super(props)
      let params = queryString.parse(this.props.location.search);
      this.state.route = {name:"",description:"",image:"", isRoad:false};
      this.state.buttonvisibles = true;

      if (params.id) {
        this.state.idroute = params.id;
        this.callSearch();
      } else{
        this.state.idroute = -1;
      }

      this.handlerUpload = this.handlerUpload.bind(this);
      this.handlerDelete = this.handlerDelete.bind(this);
  }

  callSearch (){

      let callback = (data, error) => {
        if (data) {
          this.setState({route:data});
          if (data.image) {
             this.setState({valid_url:this.validURLImage(data.image)});
          }
        } else {
          const msg = "No se ha encontrado el ruta";
          toast.notify(msg, {position:'top', duration: 5000});
          this.setState({buttonvisibles:false});
        }

      }
      routeService.one(this.state.idroute, callback);
  }

  validURLImage(str) {
    return str.match(/\.(jpeg|jpg|gif|png)$/) != null
  }

  callTags (){

    let callback = (datarows, error) => {
      if (datarows) {
         this.setState({tags:datarows});
      }
    }
    routeService.tags(callback);
  }

  callCategories (){

    let callback = (datarows, error) => {
      if (datarows) {
         this.setState({categories:datarows});
      }
    }
    routeService.categories(callback);
  }

 handleClickClear(event) {
   window.location.href="/rutas";
 }

 handlerUpload(image){
  var route = this.state.route;
  route.image = image;
  this.setState({route:route});
}

handlerDelete(){
  var route = this.state.route;
  route.image = "";
  this.setState({route:route});
}

  handleChange(event){
       const value = event.target.value
       var route = this.state.route;
       if (event.target.name === "name") {
         route.name = value;
       } else if (event.target.name === "description") {
         route.description = value;
       } else if (event.target.name === "image") {
         route.image = value;
         this.setState({valid_url:this.validURLImage(value)});
       } else if (event.target.name === "ranking") {
          route.ranking = value;
       }

       this.setState({route:route});
  }

   setRouteType(type) {
    const route = { ...this.state.route };
  
    if (type === "isRoad") {
      route.isRoad = true;
      route.isGamified = false;
    } else if (type === "isGamified") {
      route.isRoad = false;
      route.isGamified = true;
    } else if (type === "isPatrimonial") {
      route.isRoad = false;
      route.isGamified = false;
    }
  
    this.setState({ route });
   }

   handleClickSave(event) {
     if (this.state.route.name) {
       if(this.state.idroute !== -1){
         //EDIT
         this.callEdit();
       } else {
         //CREATE
         this.callCreate();
       }
     } else if (!this.state.route.name || this.state.route.name === "" ) {
         const msg = "El nombre es obligatorio"
         toast.notify(msg, {position:'top', duration: 5000})
     }
   }

   setChecked(field,event,checked) {
    var route = this.state.route;
     if (field === "visibleSearch") {
      route.visibleSearch = checked;
     }
     this.setState({route:route});
   }

     callCreate (){

         let callback = (datarows, error) => {
           if (datarows && datarows.data) {
              this.setState({station:datarows.data});
              this.setState({idstation:datarows.data.id});
              window.location.href="/ruta?id="+datarows.data.id;
           }

         }
         routeService.create(this.state.route, callback);
     }

     callEdit (){

         let callback = (datarows, error) => {
           if (datarows && datarows.data) {
               const msg = "Se ha guardado correctamente";
               toast.notify(msg, {position:'top', duration: 5000});
           }

         }
         routeService.edit(this.state.idroute,this.state.route, callback);
     }


  render () {
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title={(() => { if (this.state.idroute !== -1) return "Editar ruta"; else return "Nueva ruta";})() } newbutton={false} link=""/>
         <Row>
            <Col sm="6">
                <Form.Group >
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control type="text" placeholder="" name="name" value={this.state.route.name} onChange={event => this.handleChange(event)} required/>
                </Form.Group>
            </Col>
            <Col sm="3">
                <Form.Group >
                  <Form.Label>Ranking</Form.Label>
                  <Form.Control type="text" placeholder="" name="ranking" value={this.state.route.ranking} onChange={event => this.handleChange(event)} required/>
                </Form.Group>
            </Col>
            <Col sm="3" className="d-flex align-items-center">
               <Form.Group controlId="visibleSearch">
                 <Form.Check type="checkbox" label="Visible en el buscador" name="visibleSearch" checked={this.state.route.visibleSearch} onChange={(event) => this.setChecked("visibleSearch",event,!this.state.route.visibleSearch)}/>
               </Form.Group>
             </Col>
            <Col sm="9">
                 <Form.Group >
                   <Form.Label>Descripción</Form.Label>
                   <Form.Control as="textarea" rows="3" placeholder="" name="description" value={this.state.route.description} onChange={event => this.handleChange(event)} />
                 </Form.Group>
           </Col>
            <Col sm="3">
              <Form.Group controlId="routeType">
                <Form.Label>Tipo de ruta</Form.Label>
                <div>
                  <Form.Check type="radio" label="Ruta en coche" name="routeType" id="isRoad" value="isRoad" checked={this.state.route.isRoad} onChange={() => this.setRouteType("isRoad")} />
                  <Form.Check type="radio" label="Ruta Gamificada" name="routeType" id="isGamified" value="isGamified" checked={this.state.route.isGamified} onChange={() => this.setRouteType("isGamified")} />
                  <Form.Check type="radio" label="Ruta Patrimonial" name="routeType" id="isPatrimonial" value="isPatrimonial" checked={!this.state.route.isRoad && !this.state.route.isGamified} onChange={() => this.setRouteType("isPatrimonial")} />
                </div>
              </Form.Group>
            </Col>
            <Col sm="9">
                <Form.Group >
                  <Form.Label>Imagen</Form.Label>
                  <Form.Control placeholder="" name="image" value={this.state.route.image} onChange={event => this.handleChange(event)} />
                </Form.Group>
          </Col>
           <Col sm="3">
                <Image src={this.state.route.image } rounded fluid className="imagetable" style={{ display: this.state.valid_url  ? "block" : "none" }} />
          </Col>
         </Row>
         <Row>
             <Col className="text-right">
                 <Button variant="default" className="" onClick={event => this.handleClickClear(event)}>Cancelar</Button>
                 {this.state.buttonvisibles?<Button variant="primary" className="" onClick={event => this.handleClickSave(event)}>Guardar</Button>:""}
             </Col>
         </Row>
    </div></>
  };
}

export default RutaEdit;