import React from 'react';
import TitlePage from './../../components/page/title';
import NavBar from './../../components/NavBar';
import Home from './../../screens/Home';
import './Confighome.scss';
import { configurationService,podcastService, stationService } from '../../services';
import { Button, Row, Col, Form, Modal, Image } from 'react-bootstrap';
import ReactTags from 'react-tag-autocomplete';
import toast from 'toasted-notes'


class Confighome extends Home{


  constructor(props) {
      super(props)
      this.state = {
        configuration: { station: { id: "" }, podcast1: { id: "" }},
        showconfirm: false,
        stations: [],
        podcast: [],
        tags: [],
        locales: [],
        locale: 'es',
      };
  
      this.reactTags = React.createRef();
      this.reactPrograms = React.createRef();
      this.reactTagsStation = React.createRef();
      this.reactTagsExclusive = React.createRef();
      this.reactTagsTrend = React.createRef();
  
      this.loadInitialData();
    }
  
    loadInitialData() {
      this.callSearch(this.state.locale);
      this.callLanguage();
      this.callStations();
      this.callPodcast();
      this.callTags();
      this.callPrograms();
    }
  
    callLanguage() {
      configurationService.language((data, error) => {
        if (data) this.setState({ locales: data });
      });
    }
  
    callPodcast() {
      podcastService.all((data, error) => {
        if (data) {
          const podcasts = data.filter(podcast => !podcast.isPoi);
          this.setState({ podcast: podcasts });
        }
      });
    }
  
    callStations() {
      stationService.all((data, error) => {
        if (data) this.setState({ stations: data });
      });
    }
  
    callTags() {
      podcastService.tags((datarows, error) => {
        if (datarows) this.setState({ tags: datarows });
      });
    }
  
    callPrograms() {
      podcastService.programs((datarows, error) => {
        if (datarows) this.setState({ programs: datarows });
      });
    }
  
    callSearch(locale) {
      configurationService.home(locale, (data, error) => {
        if (data) {
          this.setState({ configuration: data });
        } else {
          toast.notify("No se ha encontrado la configuración por defecto", { position: 'top', duration: 5000 });
          this.setState({ buttonvisibles: false });
        }
      });
    }

   handleChangeLocale(event) {
     const locale = event.target.value
     this.setState({locale:locale});
     this.callSearch(locale);

   }

   handleChange(event) {
    const { name, value } = event.target;
    const configuration = { ...this.state.configuration };
  
    const resetFields = (index, fieldType) => {
      if (fieldType === 'podcast') {
        configuration[`urlSLide${index}`] = "";
        configuration[`tag${index}`] = null;
      } else if (fieldType === 'urlSLide') {
        configuration[`podcast${index}`] = null;
        configuration[`tag${index}`] = null;
      } else if (fieldType === 'tag') {
        configuration[`urlSLide${index}`] = "";
        configuration[`podcast${index}`] = null;
      }
    };
  
    const match = name.match(/(podcast|urlSLide|tag|image)(\d+)/);
    
    if (match) {
      const [, fieldType, index] = match;
  
      if (fieldType === "podcast") {
        configuration[`podcast${index}`] = { id: value };
        resetFields(index, 'podcast');
      } else if (fieldType === "urlSLide") {
        configuration[`urlSLide${index}`] = value;
        resetFields(index, 'urlSLide');
      } else if (fieldType === "tag") {
        configuration[`tag${index}`] = { id: value };
        resetFields(index, 'tag');
      } else if (fieldType === "image") {
        configuration[`image${index}`] = value;
      }
  
      this.setState({ configuration });
    } else if (name === "visibleApp") {
      configuration.station = { id: value };
      this.setState({ configuration });
    }
  }

    handleClickSave(event) {
      this.callSend();
    }

    onDelete (i) {
      const tags = this.state.configuration.tags.slice(0)
      tags.splice(i, 1)
      var configuration = this.state.configuration;
      configuration.tags = tags;
      this.setState({configuration:configuration});
    }

    onAddition (tag) {
      const tags = [].concat(this.state.configuration.tags, tag)
      var configuration = this.state.configuration;
      configuration.tags = tags;
      this.setState({configuration:configuration});
    }

    onDeleteProgram(i) {
      const tags = this.state.configuration.programs.slice(0)
      tags.splice(i, 1)
      var configuration = this.state.configuration;
      configuration.programs = tags;
      this.setState({configuration:configuration});
    }

    onAdditionProgram (tag) {
      const tags = [].concat(this.state.configuration.programs, tag)
      var configuration = this.state.configuration;
      configuration.programs = tags;
      this.setState({configuration:configuration});
    }

    onDeleteExclusive(i) {
      const tags = this.state.configuration.exclusives.slice(0)
      tags.splice(i, 1)
      var configuration = this.state.configuration;
      configuration.exclusives = tags;
      this.setState({configuration:configuration});
    }

    onAdditionExclusive (tag) {
      const tags = [].concat(this.state.configuration.exclusives, tag)
      var configuration = this.state.configuration;
      configuration.exclusives = tags;
      this.setState({configuration:configuration});
    }

    onDeleteTrend(i) {
      const tags = this.state.configuration.trends.slice(0)
      tags.splice(i, 1)
      var configuration = this.state.configuration;
      configuration.trends = tags;
      this.setState({configuration:configuration});
    }

    onAdditionTrend (tag) {
      const tags = [].concat(this.state.configuration.trends, tag)
      var configuration = this.state.configuration;
      configuration.trends = tags;
      this.setState({configuration:configuration});
    }

    onDeleteStation(i) {
      const tags = this.state.configuration.stations.slice(0)
      tags.splice(i, 1)
      var configuration = this.state.configuration;
      configuration.stations = tags;
      this.setState({configuration:configuration});
    }

    onAdditionStation (tag) {
      const tags = [].concat(this.state.configuration.stations, tag)
      var configuration = this.state.configuration;
      configuration.stations = tags;
      this.setState({configuration:configuration});
    }

  callSend (){

      let callback = (data, error) => {
        if (data) {
           this.setState({showconfirm:true});
        }
      }
      configurationService.edithome(this.state.configuration.id,this.state.configuration, callback);
  }

  validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+\\/=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }


  render () {
    
    return <>
      <NavBar user={this.state.user} callback={this.changeMenu}/>
      <div className="main">
         <TitlePage title="Definición de la home" newbutton={false} link=""  />
          <Row>
            <Col sm="12">
                <h5>Selecciona el idioma a modificar</h5>
            </Col>
            <Col sm="12">
                <Form.Group controlId="formTags">
                  <Form.Label></Form.Label>
                  <Form.Control as="select" name="podcast1" onChange={event => this.handleChangeLocale(event)} value={this.state.locale} >
                    {this.state.locales.map((locale,idx) => (
                        <option value={locale.locale}>{locale.locale}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
            </Col>
          </Row>
         <Row>

              <Col sm="12">
                  <h4>Primer Slider</h4>
              </Col>
              <div sm="12" className="panelslider">
                <Row>
                  <Col sm="3">
                    <Row>
                       <Col sm="12">
                       <div className='paneImageUp'>
                              <Image src={this.state.configuration.image1} alt="image" fluid hidden={!this.validURL(this.state.configuration.image1)}/>
                        </div>
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control name="image1" value={this.state.configuration.image1} onChange={event => this.handleChange(event)} />
                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Podcast</Form.Label>
                             <Form.Control as="select" name="podcast1" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast1 != null && this.state.configuration.podcast1.id} >
                               <option value="">---</option>
                               {this.state.podcast.map((podcast,idx) => (
                                   <option value={podcast.id}>{podcast.title}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                            <Form.Group >
                              <Form.Label>URL</Form.Label>
                              <Form.Control type="text" placeholder="" name="urlSLide11" value={this.state.configuration.urlSLide11} onChange={event => this.handleChange(event)} />
                            </Form.Group>
                      </Col>
                      <Col sm="12">
                         - o -
                      </Col>
                      <Col sm="12">
                          <Form.Group controlId="formTags">
                            <Form.Label>Tag</Form.Label>
                            <Form.Control as="select" name="tag11" onChange={event => this.handleChange(event)} value={this.state.configuration.tag11 != null && this.state.configuration.tag11.id} >
                              <option value="">---</option>
                              {this.state.tags.map((tag,idx) => (
                                  <option value={tag.id}>{tag.name}</option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                      </Col>
                    </Row>
                  </Col>

                  <Col sm="3">
                    <Row>
                       <Col sm="12">
                          <div className='paneImageUp'>
                                <Image src={this.state.configuration.image2} alt="image" fluid hidden={!this.validURL(this.state.configuration.image2)}/>
                          </div>
                          <Form.Label>Imagen</Form.Label>
                          <Form.Control name="image2" value={this.state.configuration.image2} onChange={event => this.handleChange(event)} />                       
                      </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Podcast</Form.Label>
                             <Form.Control as="select" name="podcast2" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast2 != null && this.state.configuration.podcast2.id} >
                               <option value="">---</option>
                               {this.state.podcast.map((podcast,idx) => (
                                   <option value={podcast.id}>{podcast.title}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                            <Form.Group >
                              <Form.Label>URL</Form.Label>
                              <Form.Control placeholder="" name="urlSLide12" value={this.state.configuration.urlSLide12} onChange={event => this.handleChange(event)} />
                            </Form.Group>
                      </Col>
                      <Col sm="12">
                         - o -
                      </Col>
                      <Col sm="12">
                          <Form.Group controlId="formTags">
                            <Form.Label>Tag</Form.Label>
                            <Form.Control as="select" name="tag12" onChange={event => this.handleChange(event)} value={this.state.configuration.tag12 != null && this.state.configuration.tag12.id} >
                              <option value="">---</option>
                              {this.state.tags.map((tag,idx) => (
                                  <option value={tag.id}>{tag.name}</option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                      </Col>
                    </Row>

                  </Col>

                  <Col sm="3">
                    <Row>
                       <Col sm="12">
                       <div className='paneImageUp'>
                              <Image src={this.state.configuration.image3} alt="image" fluid hidden={!this.validURL(this.state.configuration.image3)} />
                        </div>
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control name="image3" value={this.state.configuration.image3} onChange={event => this.handleChange(event)} />                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Podcast</Form.Label>
                             <Form.Control as="select" name="podcast3" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast3 != null && this.state.configuration.podcast3.id} >
                               <option value="">---</option>
                               {this.state.podcast.map((podcast,idx) => (
                                   <option value={podcast.id}>{podcast.title}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12"> 
                            <Form.Group >
                              <Form.Label>URL</Form.Label>
                              <Form.Control placeholder="" name="urlSLide13" value={this.state.configuration.urlSLide13} onChange={event => this.handleChange(event)} />
                            </Form.Group>
                      </Col>
                      <Col sm="12">
                         - o -
                      </Col>
                      <Col sm="12">
                          <Form.Group controlId="formTags">
                            <Form.Label>Tag</Form.Label>
                            <Form.Control as="select" name="tag13" onChange={event => this.handleChange(event)} value={this.state.configuration.tag13 != null && this.state.configuration.tag13.id} >
                              <option value="">---</option>
                              {this.state.tags.map((tag,idx) => (
                                  <option value={tag.id}>{tag.name}</option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                      </Col>
                    </Row>

                  </Col>

                   <Col sm="3">
                     <Row>
                        <Col sm="12">
                        <div className='paneImageUp'>
                              <Image src={this.state.configuration.image4} alt="image" fluid hidden={!this.validURL(this.state.configuration.image4)} />
                        </div>
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control name="image4" value={this.state.configuration.image4} onChange={event => this.handleChange(event)} />                        </Col>
                        <Col sm="12">
                            <Form.Group controlId="formTags">
                              <Form.Label>Podcast</Form.Label>
                              <Form.Control as="select" name="podcast4" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast4 != null && this.state.configuration.podcast4.id} >
                                <option value="">---</option>
                                {this.state.podcast.map((podcast,idx) => (
                                    <option value={podcast.id}>{podcast.title}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm="12">
                           - o -
                        </Col>
                        <Col sm="12">
                             <Form.Group >
                               <Form.Label>URL</Form.Label>
                               <Form.Control placeholder="" name="urlSLide14" value={this.state.configuration.urlSLide14} onChange={event => this.handleChange(event)} />
                             </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Tag</Form.Label>
                             <Form.Control as="select" name="tag14" onChange={event => this.handleChange(event)} value={this.state.configuration.tag14 != null && this.state.configuration.tag14.id} >
                               <option value="">---</option>
                               {this.state.tags.map((tag,idx) => (
                                   <option value={tag.id}>{tag.name}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                     </Row>

                   </Col>

                   <Col sm="3">
                     <Row>
                        <Col sm="12">
                        <div className='paneImageUp'>
                              <Image src={this.state.configuration.image5} alt="image" fluid hidden={!this.validURL(this.state.configuration.image5)}/>
                        </div>
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control name="image5" value={this.state.configuration.image5} onChange={event => this.handleChange(event)} />                        </Col>
                        <Col sm="12">
                            <Form.Group controlId="formTags">
                              <Form.Label>Podcast</Form.Label>
                              <Form.Control as="select" name="podcast5" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast5 != null && this.state.configuration.podcast5.id} >
                                <option value="">---</option>
                                {this.state.podcast.map((podcast,idx) => (
                                    <option value={podcast.id}>{podcast.title}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm="12">
                           - o -
                        </Col>
                        <Col sm="12">
                             <Form.Group >
                               <Form.Label>URL</Form.Label>
                               <Form.Control placeholder="" name="urlSLide15" value={this.state.configuration.urlSLide15} onChange={event => this.handleChange(event)} />
                             </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Tag</Form.Label>
                             <Form.Control as="select" name="tag15" onChange={event => this.handleChange(event)} value={this.state.configuration.tag15 != null && this.state.configuration.tag15.id} >
                               <option value="">---</option>
                               {this.state.tags.map((tag,idx) => (
                                   <option value={tag.id}>{tag.name}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                     </Row>
                   </Col>

                   <Col sm="3">
                     <Row>
                        <Col sm="12">
                        <div className='paneImageUp'>
                              <Image src={this.state.configuration.image6} alt="image" fluid hidden={!this.validURL(this.state.configuration.image6)}/>
                        </div>
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control name="image6" value={this.state.configuration.image6} onChange={event => this.handleChange(event)} />                        </Col>
                        <Col sm="12">
                            <Form.Group controlId="formTags">
                              <Form.Label>Podcast</Form.Label>
                              <Form.Control as="select" name="podcast6" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast6 != null && this.state.configuration.podcast6.id} >
                                <option value="">---</option>
                                {this.state.podcast.map((podcast,idx) => (
                                    <option value={podcast.id}>{podcast.title}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm="12">
                           - o -
                        </Col>
                        <Col sm="12">
                             <Form.Group >
                               <Form.Label>URL</Form.Label>
                               <Form.Control placeholder="" name="urlSLide16" value={this.state.configuration.urlSLide16} onChange={event => this.handleChange(event)} />
                             </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Tag</Form.Label>
                             <Form.Control as="select" name="tag16" onChange={event => this.handleChange(event)} value={this.state.configuration.tag16 != null && this.state.configuration.tag16.id} >
                               <option value="">---</option>
                               {this.state.tags.map((tag,idx) => (
                                   <option value={tag.id}>{tag.name}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                     </Row>

                   </Col>

                   <Col sm="3">
                     <Row>
                        <Col sm="12">
                        <div className='paneImageUp'>
                              <Image src={this.state.configuration.image7} alt="image" fluid hidden={!this.validURL(this.state.configuration.image7)} />
                        </div>
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control name="image7" value={this.state.configuration.image7} onChange={event => this.handleChange(event)} />                        </Col>
                        <Col sm="12">
                            <Form.Group controlId="formTags">
                              <Form.Label>Podcast</Form.Label>
                              <Form.Control as="select" name="podcast7" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast7 != null && this.state.configuration.podcast7.id} >
                                <option value="">---</option>
                                {this.state.podcast.map((podcast,idx) => (
                                    <option value={podcast.id}>{podcast.title}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm="12">
                           - o -
                        </Col>
                        <Col sm="12">
                             <Form.Group >
                               <Form.Label>URL</Form.Label>
                               <Form.Control placeholder="" name="urlSLide17" value={this.state.configuration.urlSLide17} onChange={event => this.handleChange(event)} />
                             </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Tag</Form.Label>
                             <Form.Control as="select" name="tag17" onChange={event => this.handleChange(event)} value={this.state.configuration.tag17 != null && this.state.configuration.tag17.id} >
                               <option value="">---</option>
                               {this.state.tags.map((tag,idx) => (
                                   <option value={tag.id}>{tag.name}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                     </Row>

                   </Col>

                    <Col sm="3">
                      <Row>
                         <Col sm="12">
                         <div className='paneImageUp'>
                              <Image src={this.state.configuration.image8} alt="image" fluid hidden={!this.validURL(this.state.configuration.image8)}/>
                        </div>
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control name="image8" value={this.state.configuration.image8} onChange={event => this.handleChange(event)} />                         </Col>
                         <Col sm="12">
                             <Form.Group controlId="formTags">
                               <Form.Label>Podcast</Form.Label>
                               <Form.Control as="select" name="podcast8" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast8 != null && this.state.configuration.podcast8.id} >
                                 <option value="">---</option>
                                 {this.state.podcast.map((podcast,idx) => (
                                     <option value={podcast.id}>{podcast.title}</option>
                                 ))}
                               </Form.Control>
                             </Form.Group>
                         </Col>
                         <Col sm="12">
                            - o -
                         </Col>
                         <Col sm="12">
                              <Form.Group >
                                <Form.Label>URL</Form.Label>
                                <Form.Control placeholder="" name="urlSLide18" value={this.state.configuration.urlSLide18} onChange={event => this.handleChange(event)} />
                              </Form.Group>
                        </Col>
                        <Col sm="12">
                           - o -
                        </Col>
                        <Col sm="12">
                            <Form.Group controlId="formTags">
                              <Form.Label>Tag</Form.Label>
                              <Form.Control as="select" name="tag18" onChange={event => this.handleChange(event)} value={this.state.configuration.tag18 != null && this.state.configuration.tag18.id} >
                                <option value="">---</option>
                                {this.state.tags.map((tag,idx) => (
                                    <option value={tag.id}>{tag.name}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                        </Col>
                      </Row>

                    </Col>
                </Row>
              </div>

              <Col sm="12">
                  <h4>Segundo Slider</h4>
              </Col>
              <div sm="12" className="panelslider">
                <Row>
                  <Col sm="3">
                    <Row>
                       <Col sm="12">
                       <div className='paneImageUp'>
                              <Image src={this.state.configuration.image21} alt="image" fluid hidden={!this.validURL(this.state.configuration.image21)} />
                        </div>
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control name="image21" value={this.state.configuration.image21} onChange={event => this.handleChange(event)} />                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Podcast</Form.Label>
                             <Form.Control as="select" name="podcast21" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast21 != null && this.state.configuration.podcast21.id} >
                               <option value="">---</option>
                               {this.state.podcast.map((podcast,idx) => (
                                   <option value={podcast.id}>{podcast.title}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                            <Form.Group >
                              <Form.Label>URL</Form.Label>
                              <Form.Control placeholder="" name="urlSLide21" value={this.state.configuration.urlSLide21} onChange={event => this.handleChange(event)} />
                            </Form.Group>
                      </Col>
                      <Col sm="12">
                         - o -
                      </Col>
                      <Col sm="12">
                          <Form.Group controlId="formTags">
                            <Form.Label>Tag</Form.Label>
                            <Form.Control as="select" name="tag21" onChange={event => this.handleChange(event)} value={this.state.configuration.tag21 != null && this.state.configuration.tag21.id} >
                              <option value="">---</option>
                              {this.state.tags.map((tag,idx) => (
                                  <option value={tag.id}>{tag.name}</option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                      </Col>
                    </Row>
                  </Col>

                  <Col sm="3">
                    <Row>
                       <Col sm="12">
                       <div className='paneImageUp'>
                              <Image src={this.state.configuration.image22} alt="image" fluid hidden={!this.validURL(this.state.configuration.image22)} />
                        </div>
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control name="image22" value={this.state.configuration.image22} onChange={event => this.handleChange(event)} />                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Podcast</Form.Label>
                             <Form.Control as="select" name="podcast22" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast22 != null && this.state.configuration.podcast22.id} >
                               <option value="">---</option>
                               {this.state.podcast.map((podcast,idx) => (
                                   <option value={podcast.id}>{podcast.title}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                            <Form.Group >
                              <Form.Label>URL</Form.Label>
                              <Form.Control placeholder="" name="urlSLide22" value={this.state.configuration.urlSLide22} onChange={event => this.handleChange(event)} />
                            </Form.Group>
                      </Col>
                      <Col sm="12">
                         - o -
                      </Col>
                      <Col sm="12">
                          <Form.Group controlId="formTags">
                            <Form.Label>Tag</Form.Label>
                            <Form.Control as="select" name="tag22" onChange={event => this.handleChange(event)} value={this.state.configuration.tag22 != null && this.state.configuration.tag22.id} >
                              <option value="">---</option>
                              {this.state.tags.map((tag,idx) => (
                                  <option value={tag.id}>{tag.name}</option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                      </Col>
                    </Row>

                  </Col>

                  <Col sm="3">
                    <Row>
                       <Col sm="12">
                       <div className='paneImageUp'>
                              <Image src={this.state.configuration.image23} alt="image" fluid hidden={!this.validURL(this.state.configuration.image23)} />
                        </div>
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control name="image23" value={this.state.configuration.image23} onChange={event => this.handleChange(event)} />                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Podcast</Form.Label>
                             <Form.Control as="select" name="podcast23" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast23 != null && this.state.configuration.podcast23.id} >
                               <option value="">---</option>
                               {this.state.podcast.map((podcast,idx) => (
                                   <option value={podcast.id}>{podcast.title}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                            <Form.Group >
                              <Form.Label>URL</Form.Label>
                              <Form.Control placeholder="" name="urlSLide23" value={this.state.configuration.urlSLide23} onChange={event => this.handleChange(event)} />
                            </Form.Group>
                      </Col>
                      <Col sm="12">
                         - o -
                      </Col>
                      <Col sm="12">
                          <Form.Group controlId="formTags">
                            <Form.Label>Tag</Form.Label>
                            <Form.Control as="select" name="tag23" onChange={event => this.handleChange(event)} value={this.state.configuration.tag23 != null && this.state.configuration.tag23.id} >
                              <option value="">---</option>
                              {this.state.tags.map((tag,idx) => (
                                  <option value={tag.id}>{tag.name}</option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                      </Col>
                    </Row>

                  </Col>

                   <Col sm="3">
                     <Row>
                        <Col sm="12">
                        <div className='paneImageUp'>
                              <Image src={this.state.configuration.image24} alt="image" fluid hidden={!this.validURL(this.state.configuration.image24)}/>
                        </div>
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control name="image24" value={this.state.configuration.image24} onChange={event => this.handleChange(event)} />                        </Col>
                        <Col sm="12">
                            <Form.Group controlId="formTags">
                              <Form.Label>Podcast</Form.Label>
                              <Form.Control as="select" name="podcast24" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast24 != null && this.state.configuration.podcast24.id} >
                                <option value="">---</option>
                                {this.state.podcast.map((podcast,idx) => (
                                    <option value={podcast.id}>{podcast.title}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm="12">
                           - o -
                        </Col>
                        <Col sm="12">
                             <Form.Group >
                               <Form.Label>URL</Form.Label>
                               <Form.Control placeholder="" name="urlSLide24" value={this.state.configuration.urlSLide14} onChange={event => this.handleChange(event)} />
                             </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Tag</Form.Label>
                             <Form.Control as="select" name="tag24" onChange={event => this.handleChange(event)} value={this.state.configuration.tag24 != null && this.state.configuration.tag24.id} >
                               <option value="">---</option>
                               {this.state.tags.map((tag,idx) => (
                                   <option value={tag.id}>{tag.name}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                     </Row>

                   </Col>

                   <Col sm="3">
                     <Row>
                        <Col sm="12">
                        <div className='paneImageUp'>
                              <Image src={this.state.configuration.image25} alt="image" fluid hidden={!this.validURL(this.state.configuration.image25)}/>
                        </div>
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control name="image25" value={this.state.configuration.image25} onChange={event => this.handleChange(event)} />                        </Col>
                        <Col sm="12">
                            <Form.Group controlId="formTags">
                              <Form.Label>Podcast</Form.Label>
                              <Form.Control as="select" name="podcast25" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast25 != null && this.state.configuration.podcast25.id} >
                                <option value="">---</option>
                                {this.state.podcast.map((podcast,idx) => (
                                    <option value={podcast.id}>{podcast.title}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm="12">
                           - o -
                        </Col>
                        <Col sm="12">
                             <Form.Group >
                               <Form.Label>URL</Form.Label>
                               <Form.Control placeholder="" name="urlSLide25" value={this.state.configuration.urlSLide25} onChange={event => this.handleChange(event)} />
                             </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Tag</Form.Label>
                             <Form.Control as="select" name="tag25" onChange={event => this.handleChange(event)} value={this.state.configuration.tag25 != null && this.state.configuration.tag25.id} >
                               <option value="">---</option>
                               {this.state.tags.map((tag,idx) => (
                                   <option value={tag.id}>{tag.name}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                     </Row>
                   </Col>

                   <Col sm="3">
                     <Row>
                        <Col sm="12">
                        <div className='paneImageUp'>
                              <Image src={this.state.configuration.image26} alt="image" fluid hidden={!this.validURL(this.state.configuration.image26)}/>
                        </div>
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control name="image26" value={this.state.configuration.image26} onChange={event => this.handleChange(event)} />                        </Col>
                        <Col sm="12">
                            <Form.Group controlId="formTags">
                              <Form.Label>Podcast</Form.Label>
                              <Form.Control as="select" name="podcast26" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast26 != null && this.state.configuration.podcast26.id} >
                                <option value="">---</option>
                                {this.state.podcast.map((podcast,idx) => (
                                    <option value={podcast.id}>{podcast.title}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm="12">
                           - o -
                        </Col>
                        <Col sm="12">
                             <Form.Group >
                               <Form.Label>URL</Form.Label>
                               <Form.Control placeholder="" name="urlSLide26" value={this.state.configuration.urlSLide26} onChange={event => this.handleChange(event)} />
                             </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Tag</Form.Label>
                             <Form.Control as="select" name="tag26" onChange={event => this.handleChange(event)} value={this.state.configuration.tag26 != null && this.state.configuration.tag26.id} >
                               <option value="">---</option>
                               {this.state.tags.map((tag,idx) => (
                                   <option value={tag.id}>{tag.name}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                     </Row>

                   </Col>

                   <Col sm="3">
                     <Row>
                        <Col sm="12">
                        <div className='paneImageUp'>
                              <Image src={this.state.configuration.image27} alt="image" fluid hidden={!this.validURL(this.state.configuration.image27)}/>
                        </div>
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control name="image27" value={this.state.configuration.image27} onChange={event => this.handleChange(event)} />                        </Col>
                        <Col sm="12">
                            <Form.Group controlId="formTags">
                              <Form.Label>Podcast</Form.Label>
                              <Form.Control as="select" name="podcast27" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast27 != null && this.state.configuration.podcast27.id} >
                                <option value="">---</option>
                                {this.state.podcast.map((podcast,idx) => (
                                    <option value={podcast.id}>{podcast.title}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm="12">
                           - o -
                        </Col>
                        <Col sm="12">
                             <Form.Group >
                               <Form.Label>URL</Form.Label>
                               <Form.Control placeholder="" name="urlSLide27" value={this.state.configuration.urlSLide27} onChange={event => this.handleChange(event)} />
                             </Form.Group>
                       </Col>
                       <Col sm="12">
                          - o -
                       </Col>
                       <Col sm="12">
                           <Form.Group controlId="formTags">
                             <Form.Label>Tag</Form.Label>
                             <Form.Control as="select" name="tag27" onChange={event => this.handleChange(event)} value={this.state.configuration.tag27 != null && this.state.configuration.tag27.id} >
                               <option value="">---</option>
                               {this.state.tags.map((tag,idx) => (
                                   <option value={tag.id}>{tag.name}</option>
                               ))}
                             </Form.Control>
                           </Form.Group>
                       </Col>
                     </Row>

                   </Col>

                    <Col sm="3">
                      <Row>
                         <Col sm="12">
                         <div className='paneImageUp'>
                              <Image src={this.state.configuration.image28} alt="image" fluid hidden={!this.validURL(this.state.configuration.image28)}/>
                        </div>
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control name="image28" value={this.state.configuration.image28} onChange={event => this.handleChange(event)} />                         </Col>
                         <Col sm="12">
                             <Form.Group controlId="formTags">
                               <Form.Label>Podcast</Form.Label>
                               <Form.Control as="select" name="podcast28" onChange={event => this.handleChange(event)} value={this.state.configuration.podcast28 != null && this.state.configuration.podcast28.id} >
                                 <option value="">---</option>
                                 {this.state.podcast.map((podcast,idx) => (
                                     <option value={podcast.id}>{podcast.title}</option>
                                 ))}
                               </Form.Control>
                             </Form.Group>
                         </Col>
                         <Col sm="12">
                            - o -
                         </Col>
                         <Col sm="12">
                              <Form.Group >
                                <Form.Label>URL</Form.Label>
                                <Form.Control placeholder="" name="urlSLide28" value={this.state.configuration.urlSLide28} onChange={event => this.handleChange(event)} />
                              </Form.Group>
                        </Col>
                        <Col sm="12">
                           - o -
                        </Col>
                        <Col sm="12">
                            <Form.Group controlId="formTags">
                              <Form.Label>Tag</Form.Label>
                              <Form.Control as="select" name="tag28" onChange={event => this.handleChange(event)} value={this.state.configuration.tag28 != null && this.state.configuration.tag28.id} >
                                <option value="">---</option>
                                {this.state.tags.map((tag,idx) => (
                                    <option value={tag.id}>{tag.name}</option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                        </Col>
                      </Row>

                    </Col>
                </Row>
              </div>


             <Col sm="12" className="tagsrow">
                  <Form.Group >
                    <Form.Label>Tags</Form.Label>
                    <ReactTags allowNew={true} className="tagsinput" ref={this.reactTags} tags={this.state.configuration.tags} suggestions={this.state.tags} onDelete={this.onDelete.bind(this)} onAddition={this.onAddition.bind(this)} placeholderText="Etiquetas"/>
                  </Form.Group>
            </Col>
            <Col sm="12">
                 <Form.Group>
                   <Form.Label>Programas</Form.Label>
                   <ReactTags allowNew={false} className="tagsinput" ref={this.reactTagsFeature} tags={this.state.configuration.programs} suggestions={this.state.programs} onDelete={this.onDeleteProgram.bind(this)} onAddition={this.onAdditionProgram.bind(this)} placeholderText="Programas"/>
                 </Form.Group>
           </Col>
           <Col sm="12">
                 <Form.Group>
                   <Form.Label>Exclusivos Radio Viajera</Form.Label>
                   <ReactTags allowNew={false} className="tagsinput" ref={this.reactTagsExclusive} tags={this.state.configuration.exclusives} suggestions={this.state.programs} onDelete={this.onDeleteExclusive.bind(this)} onAddition={this.onAdditionExclusive.bind(this)} placeholderText="Exclusivos Radio Viajera"/>
                 </Form.Group>
           </Col>
           <Col sm="12">
                 <Form.Group>
                   <Form.Label>Tendencias</Form.Label>
                   <ReactTags allowNew={false} className="tagsinput" ref={this.reactTagsTrend} tags={this.state.configuration.trends} suggestions={this.state.tags} onDelete={this.onDeleteTrend.bind(this)} onAddition={this.onAdditionTrend.bind(this)} placeholderText="Tendencias"/>
                 </Form.Group>
           </Col>
           <Col sm="12">
                 <Form.Group>
                   <Form.Label>Emisoras</Form.Label>
                   <ReactTags allowNew={false} className="tagsinput" ref={this.reactTagsStation} tags={this.state.configuration.stations} suggestions={this.state.stations} onDelete={this.onDeleteStation.bind(this)} onAddition={this.onAdditionStation.bind(this)} placeholderText="Emisoras"/>
                 </Form.Group>
           </Col>
           <Col sm="12">
               <Form.Group controlId="formTags">
                 <Form.Label>Radio de inicio</Form.Label>
                 <Form.Control as="select" name="station" onChange={event => this.handleChange(event)} value={this.state.configuration.station != null  && this.state.configuration.station.id} >
                   <option value="">---</option>
                   {this.state.stations.map((station,idx) => (
                       <option value={station.id}>{station.name}</option>
                   ))}
                 </Form.Control>
               </Form.Group>
           </Col>
         </Row>
         <Row>
             <Col className="text-right">
                 <Button variant="primary" className="" onClick={event => this.handleClickSave(event)}>Guardar</Button>
             </Col>
         </Row>
      </div>

      <Modal show={this.state.showconfirm} onHide={event => this.setState({showconfirm:false})}>
        <Modal.Header closeButton>
          <Modal.Title>configurationficacion enviada</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <p>Se ha enviado la configurationficación correctamente</p>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={event => this.setState({showconfirm:false})}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      </>
  };
}

export default Confighome;
